(function() {
    function formatProduct(prd) {
        var product = null;

        if (prd.product_id && prd.quantity) {
            product = {
                'brand': prd.brand,
                'category': prd.category,
                'coupon': prd.coupon,
                'currency': prd.currency,
                'image_url': prd.image_url,
                'name': prd.name,
                'position': prd.position,
                'price': prd.price,
                'product_id': prd.product_id,
                'quantity': prd.quantity,
                'sku': prd.sku,
                'url': prd.url,
                'variant': prd.variant
            }
        }

        return product;
    }

    function formatProducts(prds) {
        var products = [];
        prds.forEach(function(prd) {
            var product = formatProduct(prd);

            if (product) {
                products.push(product);
            }
        });
        return products;
    }


    /* 
     * Product List Viewed
     *
     * @listens BLOXSubscriptionProductListViewed
     * @event dataLayer#Product List Viewed
     */
    window.addEventListener('BLOXSubscriptionProductListViewed', function(ev) {
        if (window.dataLayer && ev.detail.products) {
            var products = formatProducts(ev.detail.products);

            if (products && ev.detail.list_id) {
                dataLayer.push({
                    'event': 'Product List Viewed',
                    'properties': {
                        'list_id': ev.detail.list_id,
                        'category': ev.detail.category,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'method': ev.detail.reason,
                        'paywall_display': ev.detail.paywall_display,
                        'access_rule_name': ev.detail.access_rule_name,
                        'products': products
                    }
                });
            } else {
                console.warn('ProductListViewed event did have necessary data, event not sent');
            }
        }
    });


    /* 
     * Product Clicked
     *
     * @listens BLOXSubscriptionProductClicked
     * @fires dataLayer#Product Clicked
     */
    window.addEventListener('BLOXSubscriptionProductClicked', function(ev) {
        if (window.dataLayer && ev.detail.product) {
            var product = formatProduct(ev.detail.product);

            if (product) {
                dataLayer.push({
                    'event': 'Product Clicked',
                    'properties': {
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'product': product
                    }
                });
            }
        }
    });


    /* 
     * Product Viewed
     *
     * @listens BLOXSubscriptionProductViewed
     * @fires dataLayer#Product Viewed
     */
    window.addEventListener('BLOXSubscriptionProductViewed', function(ev) {
        if (window.dataLayer && ev.detail.product) {
            var product = formatProduct(ev.detail.product);

            if (product) {
                dataLayer.push({
                    'event': 'Product Viewed',
                    'properties': {
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'product': product
                    }
                });
            }
        }
    });


    /*
     * Product Added
     *
     * @listens BLOXSubscriptionProductAdded
     * @fires dataLayer#Product Added
     */
    window.addEventListener('BLOXSubscriptionProductAdded', function(ev) {
        if (window.dataLayer && ev.detail.product) {
            var product = formatProduct(ev.detail.product);

            if (product) {
                dataLayer.push({
                    'event': 'Product Added',
                    'properties': {
                        'cart_id': ev.detail.cart_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'product': product
                    }
                });
            }
        }
    });


    /*
     * Product Removed
     *
     * @listens BLOXSubscriptionProductRemoved
     * @fires dataLayer#Product Removed
     */
    window.addEventListener('BLOXSubscriptionProductRemoved', function(ev) {
        if (window.dataLayer && ev.detail.product) {
            var product = formatProduct(ev.detail.product);

            if (product) {
                dataLayer.push({
                    'event': 'Product Removed',
                    'properties': {
                        'cart_id': ev.detail.cart_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'product': product
                    }
                });
            }
        }
    });


    /*
     * Checkout Started
     *
     * @listens BLOXSubscriptionCheckoutStarted
     * @fires dataLayer#Checkout Started
     */
    window.addEventListener('BLOXSubscriptionCheckoutStarted', function(ev) {
        if (window.dataLayer) {
            var products = formatProducts(ev.detail.products);

            if (products) {
                dataLayer.push({
                    'properties': {
                        'products': undefined
                    }
                });

                dataLayer.push({
                    'event': 'Checkout Started',
                    'properties': {
                        'checkout_id': ev.detail.checkout_id,
                        'coupon': ev.detail.coupon,
                        'currency': ev.detail.currency,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'products': products,
                        'revenue': ev.detail.revenue,
                        'shipping': ev.detail.shipping,
                        'tax': ev.detail.tax,
                        'value': ev.detail.value
                    }
                });
            }
        }
    });


    /*
     * Checkout Step Viewed
     *
     * @listens BLOXSubscriptionCheckoutStepViewed
     * @fires dataLayer#Checkout Step Viewed
     */
    window.addEventListener('BLOXSubscriptionCheckoutStepViewed', function(ev) {
        if (window.dataLayer && ev.detail.products) {
            var products = formatProducts(ev.detail.products);

            if (products) {
                dataLayer.push({
                    'event': 'Checkout Step Viewed',
                    'properties': {
                        'checkout_id': ev.detail.checkout_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'label': ev.detail.label,
                        'payment_method': ev.detail.payment_method,
                        'products': products,
                        'step': ev.detail.step
                    }
                });
            }
        }
    });


    /*
     * Checkout Step Completed
     *
     * @listens BLOXSubscriptionCheckoutStepCompleted
     * @fires dataLayer#Checkout Step Completed
     */
    window.addEventListener('BLOXSubscriptionCheckoutStepCompleted', function(ev) {
        if (window.dataLayer) {
            var products = formatProducts(ev.detail.products);

            if (products) {
                dataLayer.push({
                    'event': 'Checkout Step Completed',
                    'properties': {
                        'checkout_id': ev.detail.checkout_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'label': ev.detail.label,
                        'payment_method': ev.detail.payment_method,
                        'products': products,
                        'step': ev.detail.step
                    }
                });
            }
        }
    });


    /*
     * Payment Info Entered
     *
     * @listens BLOXSubscriptionPaymentInfoEntered
     * @fires dataLayer#Payment Info Entered
     */
    window.addEventListener('BLOXSubscriptionPaymentInfoEntered', function(ev) {
        if (window.dataLayer) {
            var products = formatProducts(ev.detail.products);

            if (products) {
                dataLayer.push({
                    'event': 'Payment Info Entered',
                    'properties': {
                        'checkout_id': ev.detail.checkout_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'step': ev.detail.step,
                        'payment_method': ev.detail.payment_method,
                        'products': products
                    }
                });
            }
        }
    });


    /*
     * Order Completed
     *
     * @listens BLOXSubscriptionOrderCompleted
     * @fires dataLayer#Order Completed
     */
    window.addEventListener('BLOXSubscriptionOrderCompleted', function(ev) {
        if (window.dataLayer) {
            var products = formatProducts(ev.detail.products);

            if (products) {
                dataLayer.push({
                    'event': 'Order Completed',
                    'properties': {
                        'checkout_id': ev.detail.checkout_id,
                        'ecommerce_channel': ev.detail.ecommerce_channel,
                        'order_id': ev.detail.order_id,
                        'affiliation': ev.detail.affiliation,
                        'subtotal': ev.detail.subtotal,
                        'revenue': ev.detail.revenue,
                        'tax': ev.detail.tax,
                        'discount': ev.detail.discount,
                        'currency': ev.detail.currency,
                        'products': products
                    }
                });
            }
        }
    });
})();
